import React from 'react'
import PropTypes from 'prop-types'
import { siteDataPropType } from 'lib/prop-types'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import { withPrefix } from 'gatsby'
import SiteMetadata from 'lib/SiteMetadata'

const switzerlandCenter = {
  lat: 46.8182,
  lng: 8.2275,
}

const mapStyle = [
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

const markerSize = 4

const ReferenceMap = props => {
  return (
    <div className="section section--padded-big">
      <div className="section__inner shadow__box">
        <Map
          google={props.google}
          style={{
            position: 'relative',
            width: '100%',
            height: '500px',
          }}
          containerStyle={{ position: 'relative' }}
          zoom={8}
          disableDefaultUI={true}
          scrollwheel={false}
          draggable={false}
          keyboardShortcuts={false}
          disableDoubleClickZoom={true}
          initialCenter={switzerlandCenter}
          onReady={(mapProps, map) => {
            map.setOptions({
              styles: mapStyle,
            })
          }}
        >
          {props.markers.map((position, i) => (
            <Marker
              key={`marker-${i}`}
              position={position}
              icon={{
                url: withPrefix('/icons/circle.svg'),
                anchor: new props.google.maps.Point(markerSize, markerSize),
                scaledSize: new props.google.maps.Size(
                  2 * markerSize,
                  2 * markerSize
                ),
              }}
            />
          ))}
        </Map>
      </div>
    </div>
  )
}

const WrappedMap = GoogleApiWrapper(props => ({
  apiKey: props.data.site.siteMetadata.googleApiKey,
}))(ReferenceMap)

const WrappedComponent = props => (
  <SiteMetadata render={data => <WrappedMap data={data} {...props} />} />
)

export default WrappedComponent

ReferenceMap.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })
  ),
  google: PropTypes.object,
  data: siteDataPropType,
}
