// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import References from 'components/Contentful/References'
import ReferenceMap from 'components/Contentful/ReferenceMap'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpen Erfahrungsberichte"
        meta={[
          { name: 'description', content: 'Hier finden Sie ausgewählte Wärmepumpen Erfahrungsberichte unserer mehr als 500 zufriedenen Kunden! Lassen Sie sich jetzt inspirieren!' },
          { name: 'keywords', content: 'Referenzen Heizungsmacher, Erfahrung Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='2xbOmyGgAiUjSHOsv7AZ0w-5DQrzszasiV60n3PeO2c3E'
          id='ueber-500-zufriedene-und-glueckliche-kunden'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "5DQrzszasiV60n3PeO2c3E",
    "updatedAt": "2023-07-25T14:02:48.935Z",
    "title": "Über 1000 zufriedene und glückliche Kunden - Unsere Referenzen",
    "size": "Groß",
    "teaser": "Die Zufriedenheit unserer Kunden steht an oberster Stelle. Bei uns finden Sie authentische Erfahrungsberichte. Fehler passieren — wichtig ist nur, wie man damit umgeht. Wir verwenden die ehrlichen Feedbacks unserer Kunden um Lob an unsere Mitarbeiter weiterzugeben und die Kritik nutzen wir um unsere Prozesse zu verbessern. In diesem Sinne danken wir den weltbesten Kunden für ihre ehrlichen und transparenten Erfahrungsberichte. Haben Sie schon einen Heizungsersatz von uns und möchten Ihre Erfahrung mit der Öffentlichkeit teilen?",
    "image": {
        "description": "Ein Bild von der Sicht auf die Photovoltaik-Anlage der Familie Heim",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/3iiX6sKxEQGUMCkWSO80qi/8f30be31f19b5f2d9d089c622d5e400c/1---PV-Anlage-formatiert.PNG"
    },
    "links": [
        {
            "type": "link",
            "id": "1sUeHwrbrpHlMwh5NWZcpz",
            "updatedAt": "2021-11-18T09:05:17.286Z",
            "label": "Referenz-Objekt besichtigen",
            "url": "/kontakt"
        }
    ],
    "anchorName": "ueber-500-zufriedene-und-glueckliche-kunden",
    "backgroundColor": "weiß"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='2xbOmyGgAiUjSHOsv7AZ0w-45GF5EpaO33SA9le4WeiAo'
          id='muster-referenzen'
        >
          <References
            {...{
    "type": "references",
    "id": "45GF5EpaO33SA9le4WeiAo",
    "updatedAt": "2023-11-16T20:47:29.202Z",
    "title": "Unsere Referenzen",
    "references": [
        {
            "type": "reference",
            "id": "2vY23DHQH2MtV9csPgOK0p",
            "updatedAt": "2023-11-16T20:47:16.627Z",
            "title": "Referenz-Video Peter Gisin, Lausen",
            "quote": {
                "type": "quote",
                "id": "2UqQv90VIrrK5fDZqdFVn5",
                "updatedAt": "2023-11-17T12:17:22.593Z",
                "quote": "Ich habe vor allem die Dienstleistung der Firma Heizungsmacher AG geschätzt, dass ich nichts machen musste. Ich bin sehr zufrieden, da der Terminplan von Grund auf richtig aufgesetzt wurde und dann auch eingehalten werden konnte.",
                "author": "Peter Gisin"
            },
            "description": "<p>Bei Gisins in Lausen wurde ein 15'000 Liter fassender Öltank demontiert und ein neuer Kellerraum geschaffen. Zugleich wurde eine neue Luft/Wasser Wärmepumpe eingebaut. Herr Gisin und die involvierten Installateure erzählen im Video, wie der Umbau abgelaufen ist.</p>\n",
            "date": "November 2023",
            "image": {
                "description": "Bild mit Peter Gisin, Lausen - Erfahrungsbereicht Demontage Öltank",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/74VDoDmn1RuKNVQ89ucfOA/58955020640bdfbedfa7f5a960f0711e/Titelbild_Referenzstory.jpg"
            },
            "link": {
                "type": "link",
                "id": "6lh1XLGMTJQglFSS0COOuk",
                "updatedAt": "2023-11-16T20:47:13.154Z",
                "label": "Interessiert? Video anschauen",
                "page": {
                    "slug": "referenz-video-peter-gisin-lausen-bl"
                }
            }
        },
        {
            "type": "reference",
            "id": "1dLImnBooLZRykfK7wuW68",
            "updatedAt": "2023-08-16T08:32:36.979Z",
            "title": "Erfahrungsbericht von Familie Heim, Hofstetten SO",
            "quote": {
                "type": "quote",
                "id": "7lLcNXkzwweyItKnJlX5T9",
                "updatedAt": "2021-01-12T13:34:25.329Z",
                "quote": "Wir wollten die Umwelt so gut wie möglich schonen.",
                "author": "Familie Heim"
            },
            "description": "<p>Die Familie Heim aus Hofstetten SO hat nicht nur eine aussen aufgestellte Luft/Wasser-Wärmepumpe von NIBE sondern gleich auch eine Photovoltaikanlage durch Heizungsmacher installieren lassen. Lesen Sie weiter und finden Sie heraus, weshalb sich die Heims für Heizungsmacher entschieden haben.</p>\n",
            "date": "August 2023",
            "image": {
                "description": "Ein Bild von Silvio Heim's Haus",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/12lkdL0jddNuH2DNGL1oDR/971da7c5f94553485bbc41b90ce11788/2308---Silvio-Heim-Referenzbild-Webseite.png"
            },
            "link": {
                "type": "link",
                "id": "3eKwj2QUlFxuyvjatWsDAo",
                "updatedAt": "2023-03-08T13:54:23.504Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "referenzen/erfahrungsbericht-von-familie-heim-aus-hofstetten-so"
                }
            }
        },
        {
            "type": "reference",
            "id": "6QRvp7MAVw7q53VfZlwkGL",
            "updatedAt": "2023-05-17T11:12:18.087Z",
            "title": "Erfahrungsbericht von Familie Gasser, Suhr AG",
            "quote": {
                "type": "quote",
                "id": "5gn6eMlcVkIgdwkTIbcDMA",
                "updatedAt": "2023-05-17T10:59:24.007Z",
                "quote": "Nach reiflicher Überlegung entschied sich die Familie Gasser für eine diskrete Wärmepumpe im Innenbereich. Nach dem Rückbau des Öltanks würde der Raum ohnehin leer stehen.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Das Haus von Beat Gasser ist jetzt mit effizienten und erneuerbaren Energiequellen ausgestattet! Im Interview erklärt Beat Gasser, warum er seine 30 Jahre alte Ölheizung ersetzen liess, wie er auf die Heizungsmacher AG gestossen ist und welcher Nutzen die Investition in eine Photovoltaikanlage hat. Mehr dazu erfahren Sie in diesem Artikel.</p>\n",
            "date": "Mai 2023",
            "image": {
                "description": "Referenzbild Erfahrungsbericht Familie Gasse aus Suhr AG",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6dyqm57XgIaTjvO6KYvnj8/9c9a7eaa493f1ccd88753f5225895186/2302---Referenz-zur-Story-Bild-Familie-Gasser.png"
            },
            "link": {
                "type": "link",
                "id": "2rPgPnKlvtLa9kDWpfNiA8",
                "updatedAt": "2023-05-17T11:12:09.471Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "erfahrungsbericht-von-familie-gasser-aus-suhr-ag"
                }
            }
        },
        {
            "type": "reference",
            "id": "6w7ihhzNwFObrdJoQVLSXE",
            "updatedAt": "2023-03-09T07:49:42.417Z",
            "title": "Erfahrungsbericht von Familie Knöpfel, Zug ZG",
            "quote": {
                "type": "quote",
                "id": "2pNnGiFWTvT2e87ThxJnku",
                "updatedAt": "2023-03-01T09:15:29.813Z",
                "quote": "Die Familie Knöpfel entschied sich, trotz ihrer erst 12 Jahre alten Ölheizung, für eine Propan-Wärmepumpe - ein klarer Gewinn für die Umwelt. Auch die Warmwasseraufbereitung wurde auf einen umweltfreundlichen Trinkwasserspeicher umgestellt. Dies ist ein deutliches Zeichen dafür, dass die Knöpfels an die Zukunft denken! ",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Die Familie Knöpfel entschied sich für einen Wechsel und ersetzte ihre Ölheizung durch eine Wärmepumpe. Lesen Sie weiter, um zu erfahren, wie diese Umstellung für Knöpfels ausgegangen ist - vor, während und nach der Umstellung.</p>\n",
            "date": "Februar 2023",
            "image": {
                "description": "Referenzbild Referenz-Story Familie Knöpfel",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2efyWA9sZyglY6w93P6IAd/eb74eae377e531138740c7923847f9a9/Familie-Kn__pfel-Referenz-zur-Story-Bild.png"
            },
            "link": {
                "type": "link",
                "id": "6YDYKrQsayfXdfrGQWA3uo",
                "updatedAt": "2023-03-08T14:06:44.852Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "referenzen/erfahrungsbericht-von-familie-knoepfel-aus-zug-zg"
                }
            }
        },
        {
            "type": "reference",
            "id": "6YaloOI9D3qTreYbTLhBiL",
            "updatedAt": "2022-10-28T08:20:25.607Z",
            "title": "Referenz-Video Daniel Bieri, Oberdorf SO",
            "quote": {
                "type": "quote",
                "id": "0QjGsQfQNMynnNBtKeKC6",
                "updatedAt": "2022-10-27T21:36:30.130Z",
                "quote": "«Die Erdsonde ist die nachhaltigste Lösung, weil der Fremdenergiebedarf am kleinsten ist. Die Investition wird sich über die Lebensdauer der Anlage rechtfertigen.» ",
                "author": "Daniel Bieri"
            },
            "description": "<p>Daniel Bieri beschloss seine 20-jährige Gasheizung durch eine Sole/Wasser-Wärmepumpe zu ersetzen. Wieso der ausgebildete Hochbautechniker, welcher beruflich auch mit Erdsondenbohrungen zu tun hat, sich ausgerechnet für die Heizungsmacher AG entschieden hat und weshalb er auf Erdwärme setzen möchte, erzählt er in diesem Referenz-Video.</p>\n",
            "date": "Oktober 2022",
            "image": {
                "description": "Titelbild Erdsondenbohrung Projekt Erfahrungsbericht von Daniel Bieri mit Geschäftsführer Thomas Schweingruber",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1xvi9geKs1wNP1cELRVjrQ/02f96f4e22bb17678940bf225770d0e4/Thumbnail_Referenzstory_351.5x164mm_DE.jpg"
            },
            "link": {
                "type": "link",
                "id": "7KvlSbxc5tGBpr4tBWhmgZ",
                "updatedAt": "2023-03-08T13:58:31.502Z",
                "label": "Interessiert? Video anschauen",
                "page": {
                    "slug": "referenzen/referenz-video-daniel-bieri-oberdorf-so"
                }
            }
        },
        {
            "type": "reference",
            "id": "4GiGZTftHLqm1f4qqXU3O3",
            "updatedAt": "2023-03-09T07:50:04.581Z",
            "title": "Erfahrungsbericht von Familie Tokay, Dulliken SO",
            "quote": {
                "type": "quote",
                "id": "51HTekQeJ76NB9arjleTLm",
                "updatedAt": "2022-06-29T07:17:33.617Z",
                "quote": "Die junge Familie Tokay hat frisch ein Eigenheim erworben, in dem eine Ölheizung über 25 Jahre ihren Dienst verrichtet hatte. Parallel zu einer Kernsanierung haben sich die neuen Eigentümer dazu entschlossen, die fossile Heizung durch eine umweltfreundliche innenaufgestellte Luft/Wasser-Wärmepumpe zu ersetzen. ",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Warum die Wahl auf diese Wärmepumpe fiel (und für Familie Tokay selbst ein wenig überraschend kam), erfahren Sie in diesem Erfahrungsbericht.</p>\n",
            "date": "Juni 2022",
            "image": {
                "description": "Ein Foto vom Haus der Familie Tokay",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4sZcRQAJTAqNJntZpnyIPj/b947ca4d574dad7f6707d07ddaf6966a/01---Format---Titelbild-Hero-2--Gross.png"
            },
            "link": {
                "type": "link",
                "id": "GL49dZdqbAPtIeCekWeQU",
                "updatedAt": "2023-03-08T13:57:35.445Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "referenzen/erfahrungsbericht-von-familie-tokay-aus-dulliken-so"
                }
            }
        },
        {
            "type": "reference",
            "id": "2e72RcIUf6qXC8YBQdERow",
            "updatedAt": "2023-03-09T07:49:05.185Z",
            "title": "Erfahrungsbericht von Familie Rüegg, Dübendorf ZH",
            "quote": {
                "type": "quote",
                "id": "33Fy2todlJGmLMgaMALkOX",
                "updatedAt": "2022-05-03T09:39:23.379Z",
                "quote": "Wir freuen uns auf die angenehm kühlen Innentemperaturen im Hochsommer.",
                "author": "Sara Rüegg"
            },
            "description": "<p>Dass Wärmepumpen nicht nur als Heizung sondern an heissen Sommertagen auch zur “Kühlung” der Fussbodenheizung dienen können, wissen die wenigsten. Und genau auf das freut sich Sara Rüegg besonders. Wie das geht, erfahren Sie in diesem Erfahrungsbericht.</p>\n",
            "date": "Mai 2022",
            "image": {
                "description": "Referenzfoto Familie Rüegg - Erdsondenbohrung",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1KpvpDRFaLMQ1LK0DDlDer/86f950b0f1b322d5c4a904f7508ba100/Bild-Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "4M0bBNm6CK86CUOwBD50fT",
                "updatedAt": "2023-03-08T13:57:04.514Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "referenzen/erfahrungsbericht-von-familie-rueegg-aus-duebendorf-zh"
                }
            }
        },
        {
            "type": "reference",
            "id": "5zVaawYMRekqoNqsBrKXkH",
            "updatedAt": "2023-03-09T07:49:13.151Z",
            "title": "Erfahrungsbericht von Familie Forrer, Elsau ZH",
            "quote": {
                "type": "quote",
                "id": "70Tah2X4tCsfYzukMwYkGk",
                "updatedAt": "2022-01-13T12:08:36.272Z",
                "quote": "Bekanntlich bringt eine Wärmepumpe viele Vorteile mit sich, wie zum Beispiel die geringen Betriebskosten als auch die praktische Bedienung der Anlage. Das Letztere scheint Familie Forrer sehr zu geniessen.",
                "author": "René Forrer"
            },
            "description": "<p>Nach diversen Beratungen von verschiedenen Wärmepumpen-Anbietern, stiess die Familie Forrer auf die Heizungsmacher AG und erfreute sich an der bedürfnisorientierten Beratung.</p>\n",
            "date": "Januar 2022",
            "image": {
                "description": "Referenzbild Familie Forrer",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/oE2JokbeeeaxamOeoLr8R/d29ea32c9f9027d144fb4e2c65c0ece7/01---Format---Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "5mUAINclvoN27FdvI94cWD",
                "updatedAt": "2023-03-08T13:56:37.883Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "referenzen/erfahrungsbericht-von-familie-forrer-aus-elsau-zh"
                }
            }
        },
        {
            "type": "reference",
            "id": "6ZXdrfll3K0bem3AVteY6C",
            "updatedAt": "2023-03-09T07:49:19.056Z",
            "title": "Erfahrungsbericht von Familie Konzett, Binz ZH",
            "quote": {
                "type": "quote",
                "id": "6GEGKM5WBnYecNW4cygzhi",
                "updatedAt": "2022-03-11T08:16:31.411Z",
                "quote": "Die ganze Planung lief wie am Schnürchen.",
                "author": "Familie Konzett"
            },
            "description": "<p>Für die Familie Konzett hat sich der Heizungsersatz gleich doppelt gelohnt. Wie der alte Tankraum zu einem \"spirituellen\" Rückzugsort der Familie Konzett geworden ist, erfahren Sie in diesem Bericht.</p>\n",
            "date": "August 2021",
            "image": {
                "description": "Die neue Wärmepumpe NIBE F2120-20 von Familie Konzett",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/35nadtSNnT8FuSGkVc9hLK/ab9b051aede10a916436119553660b20/W__rmepumpe-Familie-Konzett---Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "1E3C2qFwJWs7PEK1EFDA8l",
                "updatedAt": "2023-03-08T13:54:52.307Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "referenzen/erfahrungsbericht-von-familie-konzett-aus-binz-zh"
                }
            }
        },
        {
            "type": "reference",
            "id": "7ECw4bsOnLUuOFyoI27xk6",
            "updatedAt": "2023-03-09T07:49:25.091Z",
            "title": "Erfahrungsbericht von Familie Marti, Kallnach BE",
            "quote": {
                "type": "quote",
                "id": "2sRGe2yVYIEkeYDEnGfDC5",
                "updatedAt": "2021-05-05T09:25:38.688Z",
                "quote": "Die Anforderungen an die neue Heizung waren klar: Der produzierte Solarstrom soll zeitgleich vor Ort verbraucht werden.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>22 Jahre lang war die alte Ölheizung der Familie Marti im Einsatz. Nachdem im Jahr 2019 eine Photovoltaik-Anlage eingezogen war, kam auch die Zeit für eine passende Heizungslösung. Erfahren sie in der Story weshalb sich die Martis für Heizungsmacher entschieden haben.</p>\n",
            "date": "Mai 2021",
            "image": {
                "description": "Referenzbild - neue Wärmepumpe der Familie Marti",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/24v72sk4s6luPHlj7pvjlI/128c83bbb855aa2c0f6f3ac2fc83ffef/2012---Referenz-zur-Story-Bild.png"
            },
            "link": {
                "type": "link",
                "id": "7aQGbXlud4wBL5L3J6q4zR",
                "updatedAt": "2023-03-08T13:56:08.806Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "referenzen/erfahrungsbericht-von-familie-marti-aus-kallnach-be"
                }
            }
        },
        {
            "type": "reference",
            "id": "4M7IpoQBgQSe55CUugi3qC",
            "updatedAt": "2023-03-09T07:49:30.423Z",
            "title": "Erfahrungsbericht von Familie Wülfert, Dornach SO",
            "quote": {
                "type": "quote",
                "id": "59PFPoP32lsfyLAwSK3RtR",
                "updatedAt": "2021-02-11T09:54:43.961Z",
                "quote": "Dass das neue Heizsystem eine Kombination aus Wärmepumpe und Photovoltaikanlage wird, war für uns klar.",
                "author": "Familie Wülfert"
            },
            "description": "<p>Die Familie Wülfert aus Dornach SO hat ihre aussen aufgestellte Luft/Wasser-Wärmepumpe von NIBE aufgehübscht und passt perfekt zum Gesamtbild ihres Grundstückes. Erfahren Sie in der Story weshalb sich die Wülferts für Heizungsmacher entschieden haben.</p>\n",
            "date": "Februar 2021",
            "image": {
                "description": "Bild von der neuen Wärmepumpe der Familie Wülfert",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/53O6QYIza0sdWz6n4nGyuX/2524ff27a66d7c38a1ac3c31d4e6be95/1---Bild-Referenz-zum-Story---formatiert.png"
            },
            "link": {
                "type": "link",
                "id": "3gHryKwji1CtYOOVv8H8dG",
                "updatedAt": "2023-03-08T13:54:31.490Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "referenzen/erfahrungsbericht-von-familie-wuelfert-aus-dornach-so"
                }
            }
        },
        {
            "type": "reference",
            "id": "732KfNmJrTYXWjrJv2qVLR",
            "updatedAt": "2023-03-09T07:49:36.209Z",
            "title": "Erfahrungsbericht von Familie Eustache, Biel-Benken BL",
            "quote": {
                "type": "quote",
                "id": "5kPhH63ZLQNuiJm0GRzPtC",
                "updatedAt": "2021-02-11T09:55:00.724Z",
                "quote": "Der Tag der offenen Wärmepumpe war ausschlaggebend.",
                "author": "Familie Eustache"
            },
            "description": "<p>Die Familie Eustache aus aus Biel-Benken BL war von der «Tag der offenen Wärmepumpe» begeistert. Erfahren Sie in der Story weshalb sich die Eustaches für Heizungsmacher entschieden haben.</p>\n",
            "date": "Februar 2021",
            "image": {
                "description": "Ein Bild von der neuen Wärmepumpe der Familie Eustache",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3rkqiw0nNys3EcxZXXlJQW/f7d1e3ce7da6b28dfec8aa7dfc3cff54/1-2--Bild-WP-formatiert.png"
            },
            "link": {
                "type": "link",
                "id": "6hJmmnw3aq6GaElLXF7TqG",
                "updatedAt": "2023-03-08T13:55:20.640Z",
                "label": "Interessiert? Hier weiterlesen",
                "page": {
                    "slug": "referenzen/referenzgeschichte-familie-eustache-aus-biel-benken-bl"
                }
            }
        }
    ],
    "anchorName": "muster-referenzen",
    "description": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='2xbOmyGgAiUjSHOsv7AZ0w-52JFPWUU7UZMsb0I95xYet'
          id=''
        >
          <ReferenceMap
            {...{
    "type": "referenceMap",
    "id": "52JFPWUU7UZMsb0I95xYet",
    "updatedAt": "2020-10-07T10:11:45.404Z",
    "title": "Referenz-Karte",
    "markers": [
        {
            "lng": 6.71,
            "lat": 46.49828
        },
        {
            "lng": 7.05,
            "lat": 46.883333
        },
        {
            "lng": 7.037362399999999,
            "lat": 47.050861
        },
        {
            "lng": 7.118328500000001,
            "lat": 47.0933826
        },
        {
            "lng": 7.383175499999999,
            "lat": 47.1966015
        },
        {
            "lng": 7.3642584,
            "lat": 47.1806587
        },
        {
            "lng": 7.446418599999999,
            "lat": 47.2121208
        },
        {
            "lng": 7.347645399999999,
            "lat": 47.1621198
        },
        {
            "lng": 7.229687800000001,
            "lat": 47.1157594
        },
        {
            "lng": 7.4489326,
            "lat": 46.940366
        },
        {
            "lng": 7.443910799999999,
            "lat": 46.94923499999999
        },
        {
            "lng": 7.435868999999999,
            "lat": 46.9802762
        },
        {
            "lng": 7.455868999999999,
            "lat": 47.0002762
        },
        {
            "lng": 7.455868999999999,
            "lat": 46.9802762
        },
        {
            "lng": 7.455868999999999,
            "lat": 46.960276199999996
        },
        {
            "lng": 7.435868999999999,
            "lat": 46.960276199999996
        },
        {
            "lng": 7.415869,
            "lat": 46.960276199999996
        },
        {
            "lng": 7.415869,
            "lat": 46.9802762
        },
        {
            "lng": 7.4513399,
            "lat": 46.9982563
        },
        {
            "lng": 7.450329299999999,
            "lat": 47.0217671
        },
        {
            "lng": 7.504305299999999,
            "lat": 46.9831271
        },
        {
            "lng": 7.4967031,
            "lat": 46.9543249
        },
        {
            "lng": 7.5147658,
            "lat": 46.9336127
        },
        {
            "lng": 7.608640299999999,
            "lat": 46.9095388
        },
        {
            "lng": 7.628640299999999,
            "lat": 46.9295388
        },
        {
            "lng": 7.628640299999999,
            "lat": 46.9095388
        },
        {
            "lng": 7.416668,
            "lat": 46.933331
        },
        {
            "lng": 7.5189967,
            "lat": 46.916256
        },
        {
            "lng": 7.475034399999999,
            "lat": 46.916703
        },
        {
            "lng": 7.412360199999999,
            "lat": 46.7892444
        },
        {
            "lng": 7.3524749,
            "lat": 46.8926843
        },
        {
            "lng": 7.3209152,
            "lat": 46.9559642
        },
        {
            "lng": 7.2275095,
            "lat": 46.91909589999999
        },
        {
            "lng": 7.2812302,
            "lat": 47.0757815
        },
        {
            "lng": 7.229369799999999,
            "lat": 47.01062779999999
        },
        {
            "lng": 7.471221899999999,
            "lat": 47.1712644
        },
        {
            "lng": 7.5069284,
            "lat": 47.0500303
        },
        {
            "lng": 7.526928399999999,
            "lat": 47.070030300000006
        },
        {
            "lng": 7.532287099999999,
            "lat": 47.0888693
        },
        {
            "lng": 7.499723999999999,
            "lat": 47.02056779999999
        },
        {
            "lng": 7.7153465,
            "lat": 47.1726372
        },
        {
            "lng": 7.7353464999999995,
            "lat": 47.1926372
        },
        {
            "lng": 7.617576099999999,
            "lat": 47.0538492
        },
        {
            "lng": 7.5576032,
            "lat": 47.0954889
        },
        {
            "lng": 7.5657815,
            "lat": 47.125834
        },
        {
            "lng": 7.622438,
            "lat": 46.7535189
        },
        {
            "lng": 7.595570500000001,
            "lat": 46.7407213
        },
        {
            "lng": 7.6381387,
            "lat": 46.7792265
        },
        {
            "lng": 7.6160391,
            "lat": 46.7734044
        },
        {
            "lng": 7.5991781,
            "lat": 46.8213089
        },
        {
            "lng": 7.499330700000001,
            "lat": 46.7641056
        },
        {
            "lng": 7.5193307,
            "lat": 46.784105600000004
        },
        {
            "lng": 7.848015299999999,
            "lat": 46.6871831
        },
        {
            "lng": 7.868015299999999,
            "lat": 46.7071831
        },
        {
            "lng": 7.8689183,
            "lat": 46.6652174
        },
        {
            "lng": 7.961722499999999,
            "lat": 46.75710609999999
        },
        {
            "lng": 7.981722499999998,
            "lat": 46.77710609999999
        },
        {
            "lng": 8.0534607,
            "lat": 46.7263386
        },
        {
            "lng": 7.568881299999999,
            "lat": 47.551158
        },
        {
            "lng": 7.588881299999999,
            "lat": 47.571158000000004
        },
        {
            "lng": 7.588881299999999,
            "lat": 47.551158
        },
        {
            "lng": 7.570991899999999,
            "lat": 47.5379528
        },
        {
            "lng": 7.590991899999999,
            "lat": 47.5579528
        },
        {
            "lng": 7.590991899999999,
            "lat": 47.5379528
        },
        {
            "lng": 7.590991899999999,
            "lat": 47.517952799999996
        },
        {
            "lng": 7.57879,
            "lat": 47.52087239999999
        },
        {
            "lng": 7.558200299999999,
            "lat": 47.5159745
        },
        {
            "lng": 7.578200299999999,
            "lat": 47.5359745
        },
        {
            "lng": 7.578200299999999,
            "lat": 47.5159745
        },
        {
            "lng": 7.578200299999999,
            "lat": 47.495974499999996
        },
        {
            "lng": 7.558200299999999,
            "lat": 47.495974499999996
        },
        {
            "lng": 7.5382003,
            "lat": 47.495974499999996
        },
        {
            "lng": 7.5382003,
            "lat": 47.5159745
        },
        {
            "lng": 7.5382003,
            "lat": 47.5359745
        },
        {
            "lng": 7.558200299999999,
            "lat": 47.5359745
        },
        {
            "lng": 7.598200299999999,
            "lat": 47.5559745
        },
        {
            "lng": 7.598200299999999,
            "lat": 47.5159745
        },
        {
            "lng": 7.598200299999999,
            "lat": 47.4759745
        },
        {
            "lng": 7.558200299999999,
            "lat": 47.4759745
        },
        {
            "lng": 7.518200299999999,
            "lat": 47.4759745
        },
        {
            "lng": 7.518200299999999,
            "lat": 47.5159745
        },
        {
            "lng": 7.518200299999999,
            "lat": 47.5559745
        },
        {
            "lng": 7.5219248,
            "lat": 47.5098182
        },
        {
            "lng": 7.541924799999999,
            "lat": 47.5298182
        },
        {
            "lng": 7.541924799999999,
            "lat": 47.5098182
        },
        {
            "lng": 7.541924799999999,
            "lat": 47.489818199999995
        },
        {
            "lng": 7.5219248,
            "lat": 47.489818199999995
        },
        {
            "lng": 7.5019248,
            "lat": 47.489818199999995
        },
        {
            "lng": 7.5019248,
            "lat": 47.5098182
        },
        {
            "lng": 7.5019248,
            "lat": 47.5298182
        },
        {
            "lng": 7.5219248,
            "lat": 47.5298182
        },
        {
            "lng": 7.5037411,
            "lat": 47.4863416
        },
        {
            "lng": 7.5152288,
            "lat": 47.4732561
        },
        {
            "lng": 7.5352288,
            "lat": 47.4932561
        },
        {
            "lng": 7.533236499999999,
            "lat": 47.5411636
        },
        {
            "lng": 7.652434,
            "lat": 47.58265900000001
        },
        {
            "lng": 7.672434,
            "lat": 47.60265900000001
        },
        {
            "lng": 7.627249900000001,
            "lat": 47.5516505
        },
        {
            "lng": 7.6485345,
            "lat": 47.5236348
        },
        {
            "lng": 7.6685345,
            "lat": 47.54363480000001
        },
        {
            "lng": 7.6919889,
            "lat": 47.5183198
        },
        {
            "lng": 7.7119889,
            "lat": 47.538319800000004
        },
        {
            "lng": 7.7119889,
            "lat": 47.5183198
        },
        {
            "lng": 7.6170412,
            "lat": 47.4790491
        },
        {
            "lng": 7.6370412,
            "lat": 47.4990491
        },
        {
            "lng": 7.6370412,
            "lat": 47.4790491
        },
        {
            "lng": 7.6370412,
            "lat": 47.459049099999994
        },
        {
            "lng": 7.6170412,
            "lat": 47.459049099999994
        },
        {
            "lng": 7.5970412000000005,
            "lat": 47.459049099999994
        },
        {
            "lng": 7.628910799999999,
            "lat": 47.4953285
        },
        {
            "lng": 7.648910799999999,
            "lat": 47.5153285
        },
        {
            "lng": 7.648910799999999,
            "lat": 47.4953285
        },
        {
            "lng": 7.6590327,
            "lat": 47.4735793
        },
        {
            "lng": 7.6420316,
            "lat": 47.4549889
        },
        {
            "lng": 7.597934399999999,
            "lat": 47.4672734
        },
        {
            "lng": 7.617934399999998,
            "lat": 47.48727340000001
        },
        {
            "lng": 7.579612399999999,
            "lat": 47.4566783
        },
        {
            "lng": 7.5894365,
            "lat": 47.4906819
        },
        {
            "lng": 7.554948800000001,
            "lat": 47.40902639999999
        },
        {
            "lng": 7.541642100000001,
            "lat": 47.3926666
        },
        {
            "lng": 7.816534799999999,
            "lat": 47.5221495
        },
        {
            "lng": 7.8442531,
            "lat": 47.5591304
        },
        {
            "lng": 7.715322899999999,
            "lat": 47.4599294
        },
        {
            "lng": 7.6943824,
            "lat": 47.4712057
        },
        {
            "lng": 7.7143824,
            "lat": 47.4912057
        },
        {
            "lng": 7.7143824,
            "lat": 47.4712057
        },
        {
            "lng": 7.7651305,
            "lat": 47.4664221
        },
        {
            "lng": 7.785130499999999,
            "lat": 47.486422100000006
        },
        {
            "lng": 7.769335599999999,
            "lat": 47.4488735
        },
        {
            "lng": 7.778102100000001,
            "lat": 47.4260906
        },
        {
            "lng": 7.7981021,
            "lat": 47.446090600000005
        },
        {
            "lng": 7.7981021,
            "lat": 47.4260906
        },
        {
            "lng": 7.7981021,
            "lat": 47.4060906
        },
        {
            "lng": 7.785113000000001,
            "lat": 47.4625964
        },
        {
            "lng": 7.81173,
            "lat": 47.43707999999999
        },
        {
            "lng": 7.8595619,
            "lat": 47.4599679
        },
        {
            "lng": 7.942283100000001,
            "lat": 47.4542491
        },
        {
            "lng": 7.496582499999999,
            "lat": 47.2112945
        },
        {
            "lng": 7.514949000000001,
            "lat": 47.2216911
        },
        {
            "lng": 7.463138900000001,
            "lat": 47.23066009999999
        },
        {
            "lng": 7.4890057,
            "lat": 47.2387859
        },
        {
            "lng": 7.5090056999999995,
            "lat": 47.25878590000001
        },
        {
            "lng": 7.5090056999999995,
            "lat": 47.2387859
        },
        {
            "lng": 7.5090056999999995,
            "lat": 47.2187859
        },
        {
            "lng": 7.4890057,
            "lat": 47.2187859
        },
        {
            "lng": 7.4690057,
            "lat": 47.2187859
        },
        {
            "lng": 7.4690057,
            "lat": 47.2387859
        },
        {
            "lng": 7.4690057,
            "lat": 47.25878590000001
        },
        {
            "lng": 7.4890057,
            "lat": 47.25878590000001
        },
        {
            "lng": 7.557985800000001,
            "lat": 47.203546
        },
        {
            "lng": 7.64602,
            "lat": 47.2480297
        },
        {
            "lng": 7.66602,
            "lat": 47.2680297
        },
        {
            "lng": 7.66602,
            "lat": 47.2480297
        },
        {
            "lng": 7.6720267,
            "lat": 47.2561103
        },
        {
            "lng": 7.5866469,
            "lat": 47.2151651
        },
        {
            "lng": 7.648410600000001,
            "lat": 47.1876571
        },
        {
            "lng": 7.5750654,
            "lat": 47.171782
        },
        {
            "lng": 7.530600199999999,
            "lat": 47.1707062
        },
        {
            "lng": 7.550600199999999,
            "lat": 47.1907062
        },
        {
            "lng": 7.480060299999999,
            "lat": 47.1486282
        },
        {
            "lng": 7.4625737,
            "lat": 47.11430470000001
        },
        {
            "lng": 7.900779399999999,
            "lat": 47.3487088
        },
        {
            "lng": 7.920779399999999,
            "lat": 47.3687088
        },
        {
            "lng": 7.920779399999999,
            "lat": 47.3487088
        },
        {
            "lng": 7.920779399999999,
            "lat": 47.328708799999994
        },
        {
            "lng": 7.865451399999999,
            "lat": 47.3511464
        },
        {
            "lng": 7.885451399999998,
            "lat": 47.3711464
        },
        {
            "lng": 7.855752600000001,
            "lat": 47.3431994
        },
        {
            "lng": 7.827473599999999,
            "lat": 47.3439097
        },
        {
            "lng": 7.8463216,
            "lat": 47.3236397
        },
        {
            "lng": 7.866321599999999,
            "lat": 47.343639700000004
        },
        {
            "lng": 7.935143799999999,
            "lat": 47.3857256
        },
        {
            "lng": 7.982187199999999,
            "lat": 47.3548887
        },
        {
            "lng": 8.0021872,
            "lat": 47.3748887
        },
        {
            "lng": 7.690717899999999,
            "lat": 47.2716158
        },
        {
            "lng": 7.710717899999999,
            "lat": 47.2916158
        },
        {
            "lng": 7.710717899999999,
            "lat": 47.2716158
        },
        {
            "lng": 7.6994293,
            "lat": 47.3132496
        },
        {
            "lng": 7.652521500000001,
            "lat": 47.3164003
        },
        {
            "lng": 7.705361799999999,
            "lat": 47.3571309
        },
        {
            "lng": 7.725324599999999,
            "lat": 47.232873
        },
        {
            "lng": 7.8216909,
            "lat": 47.2361376
        },
        {
            "lng": 8.044136,
            "lat": 47.3879577
        },
        {
            "lng": 8.064136,
            "lat": 47.407957700000004
        },
        {
            "lng": 8.064136,
            "lat": 47.3879577
        },
        {
            "lng": 8.0023269,
            "lat": 47.3736954
        },
        {
            "lng": 7.999605099999999,
            "lat": 47.4009409
        },
        {
            "lng": 8.005061099999999,
            "lat": 47.420277
        },
        {
            "lng": 8.084618200000001,
            "lat": 47.3924919
        },
        {
            "lng": 8.1046182,
            "lat": 47.412491900000006
        },
        {
            "lng": 8.088665299999999,
            "lat": 47.3749586
        },
        {
            "lng": 8.108665299999998,
            "lat": 47.3949586
        },
        {
            "lng": 8.0658539,
            "lat": 47.3371722
        },
        {
            "lng": 8.10915,
            "lat": 47.26201
        },
        {
            "lng": 8.0178304,
            "lat": 47.5082066
        },
        {
            "lng": 7.9904589,
            "lat": 47.53976549999999
        },
        {
            "lng": 8.0972936,
            "lat": 47.532246
        },
        {
            "lng": 8.1526128,
            "lat": 47.42956849999999
        },
        {
            "lng": 8.14144,
            "lat": 47.44698
        },
        {
            "lng": 8.217699,
            "lat": 47.4767224
        },
        {
            "lng": 8.237699,
            "lat": 47.4967224
        },
        {
            "lng": 8.157957,
            "lat": 47.5007578
        },
        {
            "lng": 8.2403721,
            "lat": 47.4504072
        },
        {
            "lng": 8.218839899999999,
            "lat": 47.5853787
        },
        {
            "lng": 8.2972173,
            "lat": 47.46850509999999
        },
        {
            "lng": 8.314324899999999,
            "lat": 47.4795038
        },
        {
            "lng": 8.334324899999999,
            "lat": 47.49950380000001
        },
        {
            "lng": 8.246021299999999,
            "lat": 47.4611479
        },
        {
            "lng": 8.3628843,
            "lat": 47.4439694
        },
        {
            "lng": 8.382884299999999,
            "lat": 47.4639694
        },
        {
            "lng": 8.382884299999999,
            "lat": 47.4439694
        },
        {
            "lng": 8.382884299999999,
            "lat": 47.4239694
        },
        {
            "lng": 8.4191345,
            "lat": 47.56708330000001
        },
        {
            "lng": 8.235072100000002,
            "lat": 47.411323
        },
        {
            "lng": 8.2210603,
            "lat": 47.3630416
        },
        {
            "lng": 8.2627638,
            "lat": 47.3920655
        },
        {
            "lng": 8.2827638,
            "lat": 47.412065500000004
        },
        {
            "lng": 8.262229999999999,
            "lat": 47.36505
        },
        {
            "lng": 8.2310441,
            "lat": 47.2933939
        },
        {
            "lng": 8.308459299999999,
            "lat": 47.3000881
        },
        {
            "lng": 8.3443004,
            "lat": 47.3294732
        },
        {
            "lng": 8.364799,
            "lat": 47.2861314
        },
        {
            "lng": 8.353566899999999,
            "lat": 47.1763608
        },
        {
            "lng": 8.373566899999998,
            "lat": 47.1963608
        },
        {
            "lng": 8.1147283,
            "lat": 47.329831
        },
        {
            "lng": 8.1575617,
            "lat": 47.3208129
        },
        {
            "lng": 8.1775617,
            "lat": 47.3408129
        },
        {
            "lng": 8.1409416,
            "lat": 47.2629664
        },
        {
            "lng": 8.3093072,
            "lat": 47.05016819999999
        },
        {
            "lng": 8.346261499999999,
            "lat": 47.0493957
        },
        {
            "lng": 8.2813683,
            "lat": 47.0223002
        },
        {
            "lng": 8.2674067,
            "lat": 47.0771818
        },
        {
            "lng": 8.2716874,
            "lat": 47.0936155
        },
        {
            "lng": 8.228173,
            "lat": 47.14866010000001
        },
        {
            "lng": 8.2129935,
            "lat": 47.1791998
        },
        {
            "lng": 8.3441443,
            "lat": 47.1034539
        },
        {
            "lng": 8.3657653,
            "lat": 47.07123439999999
        },
        {
            "lng": 8.3857653,
            "lat": 47.09123439999999
        },
        {
            "lng": 8.3857653,
            "lat": 47.07123439999999
        },
        {
            "lng": 8.3121008,
            "lat": 47.0249599
        },
        {
            "lng": 8.2289979,
            "lat": 46.9038736
        },
        {
            "lng": 8.0207316,
            "lat": 47.1462565
        },
        {
            "lng": 7.9941523,
            "lat": 47.253675
        },
        {
            "lng": 8.3369485,
            "lat": 47.1505786
        },
        {
            "lng": 8.5314882,
            "lat": 47.15582999999999
        },
        {
            "lng": 8.5514882,
            "lat": 47.17582999999999
        },
        {
            "lng": 8.4839055,
            "lat": 47.1986689
        },
        {
            "lng": 8.5818864,
            "lat": 47.1226008
        },
        {
            "lng": 8.6468372,
            "lat": 47.1373251
        },
        {
            "lng": 8.6668372,
            "lat": 47.1573251
        },
        {
            "lng": 8.5288623,
            "lat": 47.1069557
        },
        {
            "lng": 8.5488623,
            "lat": 47.1269557
        },
        {
            "lng": 8.5488623,
            "lat": 47.1069557
        },
        {
            "lng": 8.5488623,
            "lat": 47.0869557
        },
        {
            "lng": 8.5288623,
            "lat": 47.0869557
        },
        {
            "lng": 8.5088623,
            "lat": 47.0869557
        },
        {
            "lng": 8.412978899999999,
            "lat": 47.20392080000001
        },
        {
            "lng": 8.526752799999999,
            "lat": 47.2003879
        },
        {
            "lng": 8.546752799999998,
            "lat": 47.220387900000006
        },
        {
            "lng": 8.581477699999999,
            "lat": 47.2011982
        },
        {
            "lng": 8.438711099999999,
            "lat": 47.0572889
        },
        {
            "lng": 8.5236174,
            "lat": 47.00934789999999
        },
        {
            "lng": 8.535326099999999,
            "lat": 47.38181489999999
        },
        {
            "lng": 8.5679249,
            "lat": 47.3671055
        },
        {
            "lng": 8.587924899999999,
            "lat": 47.387105500000004
        },
        {
            "lng": 8.490609,
            "lat": 47.3728833
        },
        {
            "lng": 8.510608999999999,
            "lat": 47.3928833
        },
        {
            "lng": 8.510608999999999,
            "lat": 47.3728833
        },
        {
            "lng": 8.510608999999999,
            "lat": 47.352883299999995
        },
        {
            "lng": 8.4793558,
            "lat": 47.3860458
        },
        {
            "lng": 8.4936631,
            "lat": 47.3627973
        },
        {
            "lng": 8.481188,
            "lat": 47.44281789999999
        },
        {
            "lng": 8.3923945,
            "lat": 47.4615504
        },
        {
            "lng": 8.4123945,
            "lat": 47.4815504
        },
        {
            "lng": 8.4082107,
            "lat": 47.4431086
        },
        {
            "lng": 8.626387,
            "lat": 47.356109
        },
        {
            "lng": 8.6423728,
            "lat": 47.35163259999999
        },
        {
            "lng": 8.7154242,
            "lat": 47.2918838
        },
        {
            "lng": 8.5185325,
            "lat": 47.3141403
        },
        {
            "lng": 8.53783,
            "lat": 47.28812
        },
        {
            "lng": 8.489989999999999,
            "lat": 47.32495
        },
        {
            "lng": 8.5653092,
            "lat": 47.4289624
        },
        {
            "lng": 8.5298275,
            "lat": 47.4504583
        },
        {
            "lng": 8.4992699,
            "lat": 47.4931606
        },
        {
            "lng": 8.4811938,
            "lat": 47.5142736
        },
        {
            "lng": 8.5011938,
            "lat": 47.534273600000006
        },
        {
            "lng": 8.4657088,
            "lat": 47.5283265
        },
        {
            "lng": 8.4993768,
            "lat": 47.5598578
        },
        {
            "lng": 8.521980899999999,
            "lat": 47.5762223
        },
        {
            "lng": 8.541980899999999,
            "lat": 47.5962223
        },
        {
            "lng": 8.5006892,
            "lat": 47.5894999
        },
        {
            "lng": 8.5206892,
            "lat": 47.6094999
        },
        {
            "lng": 8.626544899999999,
            "lat": 47.7114176
        },
        {
            "lng": 8.6056599,
            "lat": 47.6832234
        },
        {
            "lng": 8.667732899999999,
            "lat": 47.7554697
        },
        {
            "lng": 8.8102584,
            "lat": 47.7073214
        },
        {
            "lng": 8.8302584,
            "lat": 47.7273214
        },
        {
            "lng": 8.5785757,
            "lat": 47.45265089999999
        },
        {
            "lng": 8.6308189,
            "lat": 47.4437747
        },
        {
            "lng": 8.588972199999999,
            "lat": 47.4156189
        },
        {
            "lng": 8.608972199999998,
            "lat": 47.4356189
        },
        {
            "lng": 8.608972199999998,
            "lat": 47.4156189
        },
        {
            "lng": 8.633153,
            "lat": 47.4213831
        },
        {
            "lng": 8.6723535,
            "lat": 47.4309373
        },
        {
            "lng": 8.7921111,
            "lat": 47.3722412
        },
        {
            "lng": 8.777935900000001,
            "lat": 47.3955327
        },
        {
            "lng": 8.866399999999999,
            "lat": 47.29888
        },
        {
            "lng": 8.8666853,
            "lat": 47.3368834
        },
        {
            "lng": 8.7982546,
            "lat": 47.4980584
        },
        {
            "lng": 8.905344099999999,
            "lat": 47.4893862
        },
        {
            "lng": 8.705355899999999,
            "lat": 47.4875152
        },
        {
            "lng": 8.725355899999998,
            "lat": 47.5075152
        },
        {
            "lng": 8.6573543,
            "lat": 47.5307206
        },
        {
            "lng": 8.5961496,
            "lat": 47.51062049999999
        },
        {
            "lng": 8.7112097,
            "lat": 47.5464124
        },
        {
            "lng": 8.624926499999999,
            "lat": 47.6606937
        },
        {
            "lng": 8.7747,
            "lat": 47.4569
        },
        {
            "lng": 8.869812099999999,
            "lat": 47.38595919999999
        },
        {
            "lng": 8.6161336,
            "lat": 47.3955306
        },
        {
            "lng": 8.636133599999999,
            "lat": 47.415530600000004
        },
        {
            "lng": 8.64598,
            "lat": 47.41036
        },
        {
            "lng": 8.66598,
            "lat": 47.43036
        },
        {
            "lng": 8.66598,
            "lat": 47.41036
        },
        {
            "lng": 8.6571567,
            "lat": 47.3800947
        },
        {
            "lng": 8.6771567,
            "lat": 47.400094700000004
        },
        {
            "lng": 8.71903,
            "lat": 47.38614
        },
        {
            "lng": 8.73903,
            "lat": 47.40614
        },
        {
            "lng": 8.693687299999999,
            "lat": 47.36992
        },
        {
            "lng": 8.76956,
            "lat": 47.33544
        },
        {
            "lng": 8.78956,
            "lat": 47.35544
        },
        {
            "lng": 8.723527599999999,
            "lat": 47.3479211
        },
        {
            "lng": 8.743527599999998,
            "lat": 47.367921100000004
        },
        {
            "lng": 8.744778,
            "lat": 47.3287081
        },
        {
            "lng": 8.801023599999999,
            "lat": 47.32225469999999
        },
        {
            "lng": 8.821023599999998,
            "lat": 47.34225469999999
        },
        {
            "lng": 8.8196744,
            "lat": 47.33313450000001
        },
        {
            "lng": 8.7585464,
            "lat": 47.30631289999999
        },
        {
            "lng": 8.8664545,
            "lat": 47.258089
        },
        {
            "lng": 8.8038753,
            "lat": 47.2581457
        },
        {
            "lng": 8.8238753,
            "lat": 47.2781457
        },
        {
            "lng": 8.7682378,
            "lat": 47.25584
        },
        {
            "lng": 8.5927209,
            "lat": 47.3184279
        },
        {
            "lng": 8.6842566,
            "lat": 47.269647
        },
        {
            "lng": 8.704256599999999,
            "lat": 47.289647
        },
        {
            "lng": 8.6969006,
            "lat": 47.2613018
        },
        {
            "lng": 8.716900599999999,
            "lat": 47.2813018
        },
        {
            "lng": 8.5414899,
            "lat": 47.3198115
        },
        {
            "lng": 8.5614899,
            "lat": 47.3398115
        },
        {
            "lng": 8.5614899,
            "lat": 47.3198115
        },
        {
            "lng": 8.696259999999999,
            "lat": 47.2097073
        },
        {
            "lng": 8.716259999999998,
            "lat": 47.2297073
        },
        {
            "lng": 8.716259999999998,
            "lat": 47.2097073
        },
        {
            "lng": 8.6495094,
            "lat": 47.2255535
        },
        {
            "lng": 8.7155642,
            "lat": 47.1934879
        },
        {
            "lng": 9.1772154,
            "lat": 47.09339920000001
        },
        {
            "lng": 8.420693,
            "lat": 47.3867071
        },
        {
            "lng": 8.42034,
            "lat": 47.31856
        },
        {
            "lng": 8.4710539,
            "lat": 47.3176744
        },
        {
            "lng": 8.4534407,
            "lat": 47.2992865
        },
        {
            "lng": 8.4734407,
            "lat": 47.319286500000004
        },
        {
            "lng": 8.4734407,
            "lat": 47.2992865
        },
        {
            "lng": 8.4595489,
            "lat": 47.27914759999999
        },
        {
            "lng": 8.4795489,
            "lat": 47.29914759999999
        },
        {
            "lng": 8.4182852,
            "lat": 47.2625202
        },
        {
            "lng": 8.4637564,
            "lat": 47.2222792
        },
        {
            "lng": 8.5699671,
            "lat": 47.2733685
        },
        {
            "lng": 8.448795,
            "lat": 47.3982611
        },
        {
            "lng": 8.468795,
            "lat": 47.4182611
        },
        {
            "lng": 8.468795,
            "lat": 47.3982611
        },
        {
            "lng": 8.468795,
            "lat": 47.378261099999996
        },
        {
            "lng": 8.448795,
            "lat": 47.378261099999996
        },
        {
            "lng": 8.4143743,
            "lat": 47.42291489999999
        },
        {
            "lng": 8.3791652,
            "lat": 47.3860478
        },
        {
            "lng": 8.399165199999999,
            "lat": 47.4060478
        },
        {
            "lng": 8.399165199999999,
            "lat": 47.3860478
        },
        {
            "lng": 8.3847302,
            "lat": 47.3536029
        },
        {
            "lng": 8.4047302,
            "lat": 47.3736029
        },
        {
            "lng": 8.3945634,
            "lat": 47.3356381
        },
        {
            "lng": 9.4647144,
            "lat": 47.4078753
        },
        {
            "lng": 9.5293867,
            "lat": 47.441412
        },
        {
            "lng": 9.646025,
            "lat": 47.382165
        },
        {
            "lng": 9.0479097,
            "lat": 47.4635617
        }
    ]
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
